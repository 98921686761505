import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import Image from "components/Image" 
//import Image from "gatsby-image"
import SEO from "components/SEO"
import Kontaktform from "components/Kontaktform"
import Success from "components/Success"
import TwoBox from "components/TwoBox"
import office from "images/foretag.png"
import TwoBoxIcons from "components/TwoBoxIcons"
import Seperate from "components/Seperate"
import Offertform from "components/OffertForm"


const Page = ({ data }) => {
  const page = data.markdownRemark 

  return (
    <Layout className="page-layout trigger-hero"> 
    
      <SEO title={page.frontmatter.seotitle} description={page.frontmatter.seodescription}/>
      {/* <Image className="page-hero-img" fluid={page.frontmatter.image.childImageSharp.fluid} alt={page.frontmatter.titleh1}/> */}
      <main className="subpage-content page-template">

      {page.frontmatter.slug === "offert" && 
         <>
          <div className="offert-holder" >
            <div className="offert-text">
              <h2>{page.frontmatter.titleh1} </h2>
              <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </div>
            <Offertform />  
          </div>         
         </> 
      }

      {page.frontmatter.slug !== "offert" && 
        <>
          <TwoBox
          Bild={page.frontmatter.image.publicURL}
          Header={page.frontmatter.titleh1} 
          TextBlock={<div dangerouslySetInnerHTML={{ __html: page.html }} />}
          />
        </> 
      }
      <Seperate /> 
      </main>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        titleh1
        titleh2
        date
        slug 
        title
        seotitle
        seodescription
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1600,  quality: 90) { 
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
